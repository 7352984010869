import React from 'react';
import './newClient.css';
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';

import pdfIcon from '../../assets/images/iconPdf.svg';
import wordIcon from '../../assets/images/iconWord.png';
import newClientPdf from '../../assets/docs/newClientForm.pdf';
import newClientDoc from '../../assets/docs/newClientForm.doc';
import newBusinessPdf from '../../assets/docs/newBusinessClientWorksheet.pdf';
import newBusinessDoc from '../../assets/docs/newBusinessClientWorksheet.doc';

const newClient = () => {

  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | New Client'/>
      <section className='newClientInfoSection'>
        <h2>New Client Information</h2>
        <p>
          If you are a new client at JRE Financial, please fill out our New Client Worksheet to tell us a bit about yourself. Please do not forget to bring a copy of your previous year's tax return along with you to your appointment. We look forward to meeting you!
        </p>
        <div className='fileDownloads labelGradient'>
          <label className='downloadLabel'>New Client Form</label>
          <div className='downloadLink'>
            <a href={newClientPdf} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download New Client PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={newClientDoc} target='_blank'  rel="noopener noreferrer">
              <img src={wordIcon} className='icon hvr-float-shadow' alt='Download New Client DOC'/>
            </a>
          </div>
        </div>
        <div className='fileDownloads labelGradient'>
          <label className='downloadLabel'>New Business Client Form</label>
          <div className='downloadLink'>
            <a href={newBusinessPdf} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download New Business Client PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={newBusinessDoc} target='_blank'  rel="noopener noreferrer">
              <img src={wordIcon} className='icon hvr-float-shadow' alt='Download New Business Client DOC'/>
            </a>
          </div>
 
        </div>
      </section>
    </div>
  )
}

export default newClient

