import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routing from './Components/routing/Routing';
import Navbar from './Components/nav/Navbar';
import Footer from './Components/footer/Footer';

const Site = () => (
  <div className='width100'>
    <Navbar />
    <Routing />
    <Footer />
  </div>
)

ReactDOM.render(<Site />, document.getElementById('root'));


serviceWorker.unregister();
