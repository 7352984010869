import React from 'react'
import './contractors.css'
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';

import pdfIcon from '../../assets/images/iconPdf.svg';
import wordIcon from '../../assets/images/iconExcel.png';
import pdf1099 from '../../assets/docs/1099Request.pdf';
import xls1099 from '../../assets/docs/1099Request.xls';
import irsDeadlinesPDF from '../../assets/docs/irsDeadlines.pdf';
import irsDeadlinesDOC from '../../assets/docs/irsDeadlines.doc';

const contractors = () => {


  return (
    
    <div className='mainContainer'>
          <ChangeTitle title='JRE | Contractors'/>
      <section className='info1099'>
        <h1>Independent Contractors</h1>
        <div className='fileDownloads labelGradient'>
            <label className='downloadLabel'>Process 1099 Request</label>
            <div className='downloadLink'>
              <a href={pdf1099} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download 1099 Request PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={xls1099} target='_blank'  rel="noopener noreferrer">
                <img src={wordIcon} className='icon hvr-float-shadow' alt='Download 1099 Request XLS'/>
              </a>
            </div>
        </div>
      </section>
      <section className='uLSection'>
        <h2>Please keep the following in mind:</h2>
        <ul>
          <li>Must have complete information before submitting.</li>
          <li>No substitute format will be accepted (i.e; e‐mails, verbal, etc).</li>
          <li>Any changes after 1099's have been prepared will be subject to additional fees.</li>  
          <li>IRS deadline to submit 1099's is on or before January 31.</li>
          <li>Five1099's are included with S-Corp preparation fee, any additional 1099's will have a fee of $10.00 each.</li>
            <li>Personal Income Tax Returns are due April 15.</li>
            <li>IRS & FTB Business Entities Income Tax Returns are due March 15 when filing on a calendar year basis. </li>
        </ul>

      </section>

      <section className='otherDeadlines'>
          <div className='fileDownloads labelGradient'>
            <label className='downloadLabel'>Other IRS Deadlines</label>
            <div className='downloadLink'>
              <a href={irsDeadlinesPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download IRS Deadlines PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={irsDeadlinesDOC} target='_blank'  rel="noopener noreferrer">
                <img src={wordIcon} className='icon hvr-float-shadow' alt='Download  IRS Deadlines DOC'/>
              </a>
            </div>
          </div>
      </section>
        

    </div>
  )
}

export default contractors

