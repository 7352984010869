import React from 'react'
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';


const laBusinessTax = () => {

  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | LA City Business Tax Info'/>
      <section className='laTax'>
        <h1>Los Angeles Business Tax</h1>

        <p>
          Please visit the City of Los Angeles Office of Finance website to register your business online and obtain a Tax Registration Certificate.          
        </p>
        <p>
            You will need:
        </p>
        <ul>
            <li> Account #, SSN, or EIN</li>
            <li> Full Address (including city, state, country, and zip code)</li>
            <li>Total Gross Receipt Amount</li>

        </ul>
      
        <div className='buttonBar'>
            <button onClick={() => window.open('https://latax.lacity.org/oofweb/eappreg/eappreg_criteria.cfm')}>Application</button>
            <button onClick={() => window.open('https://latax.lacity.org/laweb/F-logon.jsp')}>Renewal</button>
            <button onClick={() => window.open('https://finance.lacity.org/business-registration-requirements-faq')}>FAQ</button>
        </div>
        <p>
        Once you apply for a City of Los Angeles Business License you will be exempt from paying city taxes for the first 2 years if it is a new license. After the 2nd year, you can also be exempt if your revenue is less than $100k and/or $300K if you are a Creative Artist with the condition you file on or before 02/28 each year.
        </p>
      </section>    
      </div>
  )
}

export default laBusinessTax

