import React from 'react' 
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Main from '../../pages/main/Main'
import ServicesPage from '../../pages/services/services';
import NewClientPage from '../../pages/newClient/newClient';
import ClientWorksheetsPage from '../../pages/clientWorksheets/worksheets';
import IndependantContractorsPage from '../../pages/contractors/contractors';
import SosCompliancePage from '../../pages/sosCompliance/sosCompliance';
import RefundStatusPage from '../../pages/refund/refundStatus';
import LaCityBusinessTaxPage from '../../pages/laBusinessTax/laBusinessTax';
import Extensions from '../../pages/extensions/extensions';

 
const Routing = () => (

<Router>
    <Switch>
    <Route  exact path="/" component={Main} />
    <Route path="/services" component={ServicesPage} />
    <Route path="/newclient" component={NewClientPage} />
    <Route path="/worksheets" component={ClientWorksheetsPage} />
    <Route path="/contractors" component={IndependantContractorsPage} />
    <Route path="/soscompliance" component={SosCompliancePage} />
    <Route path="/refund" component={RefundStatusPage} />
    <Route path="/lacity" component={LaCityBusinessTaxPage} />
    <Route path="/extensions" component={Extensions} />
    </Switch>
</Router>
)
export default Routing 