import React from 'react'
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';
import '../../pages/clientWorksheets/worksheets.css'
import pdfIcon from '../../assets/images/iconPdf.svg';
import excelIcon from '../../assets/images/iconExcel.png';
import wordIcon from '../../assets/images/iconWord.png';

// icon imports
import { GiPoliceBadge, GiScales, GiHouse } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import { FaHospital, FaFireExtinguisher, FaChalkboardTeacher, FaChartLine, FaUserCheck } from "react-icons/fa";
import { MdLocalMovies, MdMovie, MdAttachMoney } from "react-icons/md";
// document imports

import animationWorksheetPDF from '../../assets/docs/animationExpenseWorksheet.pdf'
import animationWorksheetXLS from '../../assets/docs/animationExpenseWorksheet.xls'
import balanceSheetPDF from '../../assets/docs/balanceSheet.pdf'
import balanceSheetXLS from '../../assets/docs/balanceSheet.xls'
import businessApptChecklistPDF from '../../assets/docs/businessApptChecklist.pdf'
import businessApptChecklistDOC from '../../assets/docs/businessApptChecklist.docx'
import educatorWorksheetPDF from '../../assets/docs/educatorWorksheet.pdf'
import educatorWorksheetXLS from '../../assets/docs/educatorWorksheet.xls'
import entertainmentWorksheetPDF from '../../assets/docs/entertainmentWorksheet.pdf'
import entertainmentWorksheetXLS from '../../assets/docs/entertainmentWorksheet.xls'
import firefighterWorksheetPDF from '../../assets/docs/firefighterWorksheet.pdf'
import firefighterWorksheetXLS from '../../assets/docs/firefighterWorksheet.xls'
import generalWorksheetPDF from '../../assets/docs/generalWorksheet.pdf'
import generalWorksheetXLS from '../../assets/docs/generalWorksheet.xls'
import healthcareWorksheetPDF from '../../assets/docs/healthcareWorksheet.pdf'
import healthcareWorksheetXLS from '../../assets/docs/healthcareWorksheet.xls'
import lawEnforcementWorksheetPDF from '../../assets/docs/lawEnforcementWorksheet.pdf'
import lawEnforcementWorksheetXLS from '../../assets/docs/lawEnforcementWorksheet.xls'
import profitLossStatementPDF from '../../assets/docs/profitLossStatement.pdf'
import profitLossStatementXLS from '../../assets/docs/profitLossStatement.xls'
import rentalWorksheetPDF from '../../assets/docs/RentalPropertyWorksheet.pdf'
import rentalWorksheetXLS from '../../assets/docs/RentalPropertyWorksheet.xls'
import salesWorksheetPDF from '../../assets/docs/salesWorksheet.pdf'
import salesWorksheetXLS from '../../assets/docs/salesWorksheet.xls'



const worksheets = () => {

  return (

    <div className='mainContainer'>
          <ChangeTitle title='JRE | Client Worksheets'/>
      <section className='yourAppointment'>
        <h2>Your Appointment</h2>
        <p>
          Make sure you have everything you need to get the most out of your appointment.
        </p>
        <div className='fileDownloads labelGradient'>
          <FaUserCheck className='iconWorksheet downloadLink'/>
          <label className='downloadLabel'>Personal Appointment Checklist</label>
          <div className='downloadLink'>
            <a href={generalWorksheetPDF} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Personal Appointment Checklist PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={generalWorksheetXLS} target='_blank'  rel="noopener noreferrer">
              <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Personal Appointment Checklist DOC'/>
            </a>
          </div>
        </div>
        <div className='fileDownloads labelGradient'>
          <GoChecklist className='iconWorksheet downloadLink'/>
          <label className='downloadLabel'>Business Appointment Checklist</label>
          <div className='downloadLink'>
            <a href={businessApptChecklistPDF} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Business Appointment Checklist PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={businessApptChecklistDOC} target='_blank'  rel="noopener noreferrer">
              <img src={wordIcon} className='icon hvr-float-shadow' alt='Download Business Appointment Checklist DOC'/>
            </a>
          </div>
        </div>
      </section>

      <div className='accentBar'></div>

      <section className='incomeWorksheet'>
      <h2>Financial Statements</h2>
        <div className='fileDownloads labelGradient'>
          <MdAttachMoney className='iconWorksheet downloadLink'/>
          <label className='downloadLabel'>Profit and Loss</label>
          <div className='downloadLink'>
            <a href={profitLossStatementPDF} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Profit and Loss PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={profitLossStatementXLS} target='_blank'  rel="noopener noreferrer">
              <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Profit and Loss XLS'/>
            </a>
          </div>
        </div>
        <div className='fileDownloads labelGradient'>
          <GiScales className='iconWorksheet downloadLink'/>
          <label className='downloadLabel'>Balance Sheet</label>
          <div className='downloadLink'>
            <a href={balanceSheetPDF} target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Balance Sheet PDF'/>
            </a>
          </div>
          <div className='downloadLink'>
            <a href={balanceSheetXLS} target='_blank'  rel="noopener noreferrer">
              <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Balance Sheet XLS'/>
            </a>
          </div>
        </div>
      </section>

      <div className='accentBar'></div>

      <section className='expenseWorksheets'>
        <h2>Expense Worksheets</h2>
          {/* <div className='fileDownloads labelGradient'>
            <MdPerson  className='iconWorksheet downloadLink'/>
            <label className='downloadLabel'>General</label>
            <div className='downloadLink'>
              <a href={generalWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download general worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink downloadLink'>
              <a href={generalWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download general worksheet XLS'/>
              </a>
            </div>
          </div>          */}
          <div className='fileDownloads labelGradient'>
            <MdLocalMovies className='iconWorksheet downloadLink animateFilmStrip'/>
            <label className='downloadLabel'>Animation</label>
            <div className='downloadLink'>
              <a href={animationWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Animation worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={animationWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Animation worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <FaChartLine className='iconWorksheet downloadLink' />
            <label className='downloadLabel'>Business or Sales</label>
            <div className='downloadLink'>
              <a href={salesWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Business worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={salesWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Business worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <MdMovie className='iconWorksheet downloadLink' />
            <label className='downloadLabel'>Entertainment Industry</label>
            <div className='downloadLink'>
              <a href={entertainmentWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Entertainment worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={entertainmentWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Entertainment worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <FaChalkboardTeacher className='iconWorksheet downloadLink' />
            <label className='downloadLabel'>Educator</label>
            <div className='downloadLink'>
              <a href={educatorWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Educator Worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={educatorWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Educator Worksheet XLS'/>
              </a>
            </div>
          </div>  
          <div className='fileDownloads labelGradient'>
            <FaFireExtinguisher className='iconWorksheet downloadLink' />
            <label className='downloadLabel'>Firefighter</label>
            <div className='downloadLink'>
              <a href={firefighterWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Firefighter worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={firefighterWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Firefighter worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <FaHospital className='iconWorksheet downloadLink'/>
            <label className='downloadLabel'>Healthcare Worker</label>
            <div className='downloadLink'>
              <a href={healthcareWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Healthcare worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={healthcareWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Healthcare worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <GiPoliceBadge className='iconWorksheet downloadLink'/>
            <label className='downloadLabel'>Law Enforcement</label>
            <div className='downloadLink'>
              <a href={lawEnforcementWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Law Enforcement worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={lawEnforcementWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Law Enforcement worksheet XLS'/>
              </a>
            </div>
          </div>
          <div className='fileDownloads labelGradient'>
            <GiHouse className='iconWorksheet downloadLink'/>
            <label className='downloadLabel'>Rental Properties</label>
            <div className='downloadLink'>
              <a href={rentalWorksheetPDF} target='_blank'  rel="noopener noreferrer">
                <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download Rental Properties worksheet PDF'/>
              </a>
            </div>
            <div className='downloadLink'>
              <a href={rentalWorksheetXLS} target='_blank'  rel="noopener noreferrer">
                <img src={excelIcon} className='icon hvr-float-shadow' alt='Download Rental Properties worksheet XLS'/>
              </a>
            </div>
          </div>
      </section> 
    </div>
  )
}

export default worksheets

