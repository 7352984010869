import React from 'react';
import './services.css';
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';

import Pic1 from '../../assets/images/office1.png'
import Pic2 from '../../assets/images/office2.png'
import Pic3 from '../../assets/images/office3.png'

const services = () => {

  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | Services'/>
      <section className='imgRow'>
        <img src={Pic1} className='servicesImg' alt=''/>
        <img src={Pic2} className='servicesImg' alt=''/>
        <img src={Pic3} className='servicesImg' alt=''/>
      </section>
      
<section className='taxPrep'>
          <h2>Federal and State Tax Preparation </h2>
        <p>
          We prepare all types of federal and state tax returns. To ensure all legal deductions are being applied, we will provide you with comprehensive expense worksheets to assist you. We can prepare multiple state returns in conjunction with taxpayers relocating or working outside their state of residences during the year. We also amend previous years' tax returns to correct errors and omissions. 
        </p>
</section>
<section className='efile'>
  
          <h2>E-File Service</h2>
        <p>
          At JRE Financial Services Inc. we are committed to providing ourclients with the E-File service free of charge for both Federal and State returns. With the direct deposit option, your refund will be directly deposited to your checking or savings account when released by the IRS.
        </p>
</section>
    </div>
  )
}

export default services
