import React from 'react';
import awards from '../../assets/images/awards.jpg'
import './main.css';
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';

const Main = (props) => {
  
  const routeChange = (path) =>  {
    props.history.push(path);
  }
  
  return (
    <div className='mainContainer'>
    <ChangeTitle title='JRE | Home'/>

      {/* <div className='attention red'>
        <label>* Special Bulletin! *</label>
        <p>
            Due to COVID19, the IRS and State of CA have extended the deadlines for tax filing and payment until July 15, 2020!
        </p>
      </div> */}


      <div className='mainGreeting'>
        
        
        <img src={awards} alt='JRE Awards' className='greetingImg'/> 

        <div className="covidBox"> 
            <label>
              We're here for you. <br/><br/>
              You're welcome to call, email or drop off your taxes. <br/>
              Social distancing has never been more friendly!
            </label>
        </div>
        {/* <div className='aboutBoxBorder'>
          <div className='aboutBox'>
            <label>
              Frank Franco and staff 
              are fully prepared to provide you
              with the attention to detail 
              and the experience you deserve. 
              <br/> 
              <br/>
              We want to make your income tax filing 
              and Real Estate transactions as 
              painless and stress free as possible!        
              </label>
          </div>
        </div> */}
      </div> 
      <div className="buttonBar"> 
          <button className="covidButton" onClick={() => window.open('https://www.irs.gov/coronavirus/economic-impact-payments')}>Economic Impact Payments</button>
          <button className="covidButton" onClick={() => routeChange('/extensions')}>IRS Extension</button>
      </div>
      <div className='buttonBar'>
        <button onClick={() => routeChange('/refund')}>Refund Status</button>
        <button onClick={() => routeChange('/lacity')}>LA Business Tax</button>
        <button onClick={() => window.open('https://www.labor.ca.gov/employmentstatus')}>AB5</button>
        <button onClick={() => window.open('https://www.sagaftra.org/important-message-california-ab-5-and-loan-out-companies')}>Loan-outs/AB5</button>
      </div>
      <div className='infoContainer'>
        {/* <section className='infoHours'>
          <h2> Hours of Operation:</h2>
          <p>
            <label className='boldText'>During Tax Season </label><br/>
            (February 1 - April 15) <br/>
            Tuesday - Saturday 9:00am - 7:30pm<br/>
            Sunday 11:00am - 5:00pm<br/>
          </p>
          <p>
            <label className='boldText'>Off Season</label><br/>
              (April 16 - January 31) <br/>
              Tuesday - Thursday 11am - 5pm <br/>
              Or By Appointment <br/>
          </p>
        </section> */}
        <section className='infoLocation'>
          {/* <div>
            <h2>Conveniently Located At:</h2>
            2314 W Magnolia Blvd<br/>
            Burbank, CA 91506
          </div> */}
          <div>
            <h2>Email Us:</h2>
            JREtax@gmail.com<br/>
            or <br/>
            Jim at jmailliard@aol.com
          </div>
          <div>
            <h2>Call Us:</h2>
            (818) 843-8033
          </div>
          <div>
            <h2>Fax Us:</h2>
            (818) 843-3975 
          </div>
        </section>

      </div>
   </div>
  );
}

export default Main








