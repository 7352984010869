import React from 'react'
import './refundStatus.css'
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';


const refundStatus = () => {

  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | Refund Status'/>
      <section className='refundContainer'>
          <h1>Track Your Refund</h1>
      </section>
      <div className='refundStatus'>
      <section className='refundItem'> 
        <h2>Federal Refund</h2>       
            <p>You will need:</p>
        <ul>
          <li>Your social security number</li>
          <li>Your Filing status (Single, Married, etc...)</li>
          <li>Your refund amount </li>
        </ul>
        <div className='buttonBar'>
          <button onClick={() => window.open('https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp')}>Federal Refund</button>
        </div>
      </section>
      <section className='refundItem'> 
        <h2>Amended Federal Return</h2>       
            <p>You will need:</p>
        <ul>
          <li>Your social security number</li>
          <li>Your date of birth</li>
          <li>Your zipcode</li>
        </ul>
        <div className='buttonBar'>
          <button onClick={() => window.open('https://www.irs.gov/filing/wheres-my-amended-return')}>Amended Return</button>
        </div>
      </section>
      <section className='refundItem'>
      <h2>California Refund</h2>       
        <p>You will need:</p>
      <ul>
        <li>Social Security number</li>
        <li>Your exact refund amount</li>
        <li>House numbers and zip code from your mailing address</li>
      </ul>
        <div className='buttonBar'>
            <button onClick={ () => window.open('https://www.ftb.ca.gov/refund/index.asp?WT.mc_id=akRefund')}>CA State Refund</button>
        </div>
      </section>
      </div>
    </div>
  )
}

export default refundStatus

