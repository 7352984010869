import React, { Component } from 'react';
import './footer.css';
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";



export default class Footer extends Component {
  render() {
    return (
      <footer className='footer'>
        <section className='footerItem'>
          <FaMapMarkerAlt className='footerIcon'/>
          {/* <img className='footerIcon' alt='Map Icon'/> */}
            <div className='footerItemInfo'>
              <label className='boldText textAlignLeft'>VISIT</label>
                <label className='textAlignLeft'>
                  <a href='https://goo.gl/maps/DmoU2Mkfe3L6KnQEA' target='_blank' rel="noopener noreferrer">
                    2314 W. Magnolia Blvd
                    <br/>

                    Burbank, CA 91506
                    </a>
                </label>
            
            </div>
        </section>
        <section className='footerItem'>
          <MdLocalPhone className='footerIcon'/>
          {/* <img className='footerIcon' alt='Phone Icon'/> */}
            <div className='footerItemInfo'>
              <label className='boldText textAlignLeft'>CALL</label>
              <label className='textAlignLeft'>TEL:(818) 843 - 8033</label>
              <label className='textAlignLeft'>FAX: (818) 843 - 3975</label>
            </div>        
        </section>
        <section className='footerItem'>
          {/* <img className='footerIcon' alt='Email Icon'/> */}
          <IoIosMail className='footerIcon'/>
            <div className='footerItemInfo'>
              <label className='boldText textAlignLeft'>EMAIL</label>
              <label className='textAlignLeft'><a href='mailto:jretax@gmail.com'>jretax@gmail.com</a></label>
            </div>        
        </section>
      </footer>
    )
  }
}

