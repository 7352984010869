import React from 'react';
import './extensions.css';
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';

import pdfIcon from '../../assets/images/iconPdf.svg';

const extensions = () => {

  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | IRS Extensions'/>
      <section className='newClientExtSection'>
        <h2>IRS Extensions</h2>
        <p>
          Please use the Form 4868 to mail your tax extension if we are meeting with you after the April deadline. 
        </p>
        <div className='fileDownloads labelGradient'>
          <label className='downloadLabel'>File Extension</label>
          <div className='downloadLink'>
            <a href='https://www.irs.gov/pub/irs-pdf/f4868.pdf' target='_blank'  rel="noopener noreferrer">
              <img src={pdfIcon} className='iconPdf hvr-float-shadow' alt='Download File Extension PDF from IRS.gov'/>
            </a>
          </div>
          <div className='downloadLink'></div>
        </div>
        <div className='extensionOl'>
          <ol>
            <li>Print Form 4868​</li>
            <li>Fill out "Part 1" at the bottom of Page 1</li>
            <li>Mail form to the appropriate address located on Page 4 before the April deadline.</li>
        </ol>
        <p>
          Department of the Treasury<br/>
          Internal Revenue Service Center<br/>
          Fresno, CA 93888-0045<br/>
          (for California residents only) 
        </p>
        </div>
      </section>
    </div>
  )
}

export default extensions

