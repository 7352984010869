import React from 'react'
import './sosCompliance.css'
import { ChangeTitle } from '../../Components/changeTitle/ChangeTitle.js';



const sosCompliance = () => {
  return (
    <div className='mainContainer'>
      <ChangeTitle title='JRE | Compliance'/>
      <div className='h2'>
        <h1>California Secretary of State Compliance </h1>
      </div>
      <section className='corp'>
        <h2>Corporations</h2>
        <p>
          Statement of Information renewals for corporations are due 
        <label className='boldText italicText'> annually</label> the
          last day of the month your entity was formed. 
        </p>
        <div className='buttonDiv'>
          <a href='https://businesssearch.sos.ca.gov/?filing=corp' target='_blank' rel="noopener noreferrer"><button>Corporate Compliance</button></a>
        </div >      
      </section>

      <div className='accentBar'></div>

      <section className='llc'>
          <h2>LLC's</h2>
        <p>
        Statement of Information renewals for LLC's are due <label className='boldText italicText'>every 2 years</label> the last day of the month your entity was formed. 
          </p>
<div className='buttonDiv'>
            <a href='https://llcbizfile.sos.ca.gov/SI' target='_blank' rel="noopener noreferrer"><button>LLC Compliance</button></a>

</div> 
      </section>

    
    </div>
  )
}

export default sosCompliance

